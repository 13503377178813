<template>
	<CSidebar
		position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible"
		@visible-change="(event) => $store.commit({type: 'updateSidebarVisible',value: event})">

		<CSidebarBrand>
			<img class="sidebar-brand-full" alt="Mixr Logo" width="100" :src="require('@/assets/deeplink/logo.png')" />
			<img class="sidebar-brand-narrow" width="40" :src="require('@/assets/deeplink/mixr.svg')" />
		</CSidebarBrand>

		<CSidebarNav>
			<CNavItem>
				<router-link class="nav-link" :to="{ name: 'Dashboard' }" exact exact-active-class="active"><CIcon  customClassName="nav-icon" icon="cil-speedometer"/> Dashboard</router-link>
			</CNavItem>
			<!-- User Management -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['User List','User View']) }" class="nav-link" :to="{ name: 'User List' }"><CIcon  customClassName="nav-icon" icon="cil-user"/> User Management</router-link>
			</CNavItem>
			<!-- Report User Management -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Report User List', 'Report User View']) }" class="nav-link" :to="{ name: 'Report User List' }"><CIcon  customClassName="nav-icon" icon="cilUserX"/>Report User Management</router-link>
			</CNavItem>
			<!-- Gender Management -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Gender List', 'Gender Edit', 'Gender Create']) }" class="nav-link" :to="{ name: 'Gender List' }"><CIcon  customClassName="nav-icon" icon="cilWc"/>Gender Management</router-link>
			</CNavItem>
			<!-- Report Type Management -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Report Type List', 'Report Type Edit', 'Report Type Create']) }" class="nav-link" :to="{ name: 'Report Type List' }"><CIcon  customClassName="nav-icon" icon="cilReportSlash"/>Report Type Management</router-link>
			</CNavItem>
			<!-- Ethnicity Management -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Ethnicity List', 'Ethnicity Edit', 'Ethnicity Create']) }" class="nav-link" :to="{ name: 'Ethnicity List' }"><CIcon  customClassName="nav-icon" icon="cilPeople"/>Ethnicity Management</router-link>
			</CNavItem>
			<!-- Relationship Goal Management -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['Relationship Goal List', 'Relationship Goal Edit', 'Relationship Goal Create']) }" class="nav-link" :to="{ name: 'Relationship Goal List' }"><CIcon  customClassName="nav-icon" icon="cilList"/>Relationship Goal</router-link>
			</CNavItem>
		</CSidebarNav>
		<CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleUnfoldable')"/>
	</CSidebar>
</template>
  
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
// import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
export default {
	name: 'AppSidebar',
	data() {
		return {
            isDeveloper:false
		}
	},
	setup() {
		const store = useStore()
		return {
			logoNegative,
			sygnet,
			sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
			sidebarVisible: computed(() => store.state.sidebarVisible),
		}
	},
	methods: {
		isRouteActive(routeName) {
			return routeName.includes(this.$route.name);
		}
	}
}
</script>

<style scoped>
a.nav-link {
	color: #fff !important;
}

.nav-icon{
	color: #fff !important;
}
</style>