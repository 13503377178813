<template>
	<CFooter style="display: flex; justify-content: space-between; align-items: center;">
		<div>
			<span class="ms-1">&copy; {{ new Date().getFullYear() }} MixR</span>
		</div>
		<div style="display: flex; justify-content: center; flex: 1;">
			<a href="https://wvelabs.com" target="_blank" title="Developed by Wve Labs">
				<img :src="require('@/assets/deeplink/wve.svg')" alt="Wve Labs">
			</a>
		</div>
	</CFooter>
</template>

<script>
export default {
  name: 'AdminFooter'
}
</script>

<style scoped>
	.footer{
		background: #000;
		color: #fff;
	}
</style>
